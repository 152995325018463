import axiosInstance, { axiosNewInstance, sessionId, mode } from './axios';

// --------Users----------
export const usersApi = {
  getUsers() {
    return axiosInstance
      .get(
        `member/list?fields=birthDate,sinceDate,officeId,positionId${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  getUsersNew() {
    return axiosNewInstance.get('member?fields=*').then((response) => {
      return response;
    });
  },
  getUsersBirthdayFields() {
    return axiosNewInstance
      .get('member/team?fields=FirstName,LastName,BirthDate')
      .then((response) => {
        return response;
      });
  },
  getUsersBySearch(query) {
    return axiosNewInstance
      .get(`member/team?fields=*&q=${query}`)
      .then((response) => {
        return response;
      });
  },
  getMyTeam() {
    return axiosInstance
      .get(
        `member/managed${
          mode === 'development' ? '?sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  getMe() {
    return axiosInstance
      .get(
        `member/me${mode === 'development' ? '?sessionId=' + sessionId : ''}`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  getMeNew() {
    return axiosNewInstance.get(`member/me?fields=*`).then((response) => {
      return response;
    });
  },
  updateMe(firstName, lastName, email, birthDate, phoneNumber, positionId) {
    return axiosNewInstance.put(
      `me/update?fields=FirstName,LastName,Email,BirthDate,PhoneNumber,PositionId`,
      {
        firstName,
        lastName,
        email,
        birthDate,
        phoneNumber,
        positionId,
      }
    );
  },
  getMember(id) {
    return axiosInstance
      .get(
        `member/get?memberIds=${id}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  getMemberNew(id) {
    return axiosNewInstance
      .get(`member/${id}/show?fields=*`)
      .then((response) => {
        return response;
      });
  },
  getMembers(ids, fields) {
    return axiosInstance
      .get(
        `member/get?memberIds=${ids}&fields=${fields}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  getOfficeList() {
    return axiosInstance
      .get(
        `office/list${mode === 'development' ? '?sessionId=' + sessionId : ''}`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  getOfficeListNew() {
    return axiosNewInstance.get(`/office?fields=*`).then((response) => {
      return response;
    });
  },
  getProjectsList() {
    return axiosInstance
      .get(
        `project/managed?fields=memberIds${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  getProjectsListNew() {
    return axiosNewInstance.get(`/project?fields=*`).then((response) => {
      return response;
    });
  },
  getProjectsListBySearch(query) {
    return axiosNewInstance
      .get(`project/search?fields=*&q=${query}`)
      .then((response) => {
        return response;
      });
  },

  getNotesCategoriesList() {
    return axiosNewInstance
      .get(`memberNoteCategory?fields=*`)
      .then((response) => {
        return response;
      });
  },

  getNotesList() {
    return axiosNewInstance.get(`memberNote?fields=*`).then((response) => {
      return response;
    });
  },

  createNote(categoryId, content, createdAt, memberId, pinned) {
    return axiosNewInstance
      .post(
        `/memberNote/store?fields=CategoryId,Content,CreatedAt,MemberId,Pinned`,
        {
          categoryId: categoryId,
          content: content,
          createdAt: createdAt,
          memberId: memberId,
          pinned: pinned,
        }
      )
      .then((response) => {
        return response;
      });
  },

  removeNote(noteId) {
    return axiosNewInstance
      .post(`/memberNote/${noteId}/delete`)
      .then((response) => {
        return response;
      });
  },

  updateNote(noteId, content, pinned) {
    return axiosNewInstance
      .put(`/memberNote/${noteId}/update?fields=Pinned,Content`, {
        content: content,
        pinned: pinned,
      })
      .then((response) => {
        return response;
      });
  },

  getProject(projectIds) {
    return axiosInstance
      .get(
        `project/get?projectIds=${projectIds}&fields=memberIds${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  getProjectNew(projectId) {
    return axiosNewInstance
      .get(`/project/${projectId}/show?fields=*`)
      .then((response) => {
        return response;
      });
  },
  addProject(memebrId, projectId) {
    return axiosInstance
      .post(
        `member/project/bind?memberId=${memebrId}&projectId=${projectId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  addMemberToProject(memberId, projectId) {
    return axiosNewInstance
      .post(`/project/${projectId}/bindMembers?memberId=${memberId}`)
      .then((response) => {
        return response;
      });
  },
  bindProjectToMember(memberId, projectId) {
    return axiosNewInstance.post(
      `/member/${memberId}/bindProjects?projectId=${projectId}`
    );
  },
  unbindProjectToMember(memberId, projectId) {
    return axiosNewInstance.post(
      `/member/${memberId}/unbindProjects?projectId=${projectId}`
    );
  },
  bindMemberToBranch(memberId, branchId) {
    return axiosNewInstance.post(
      `/branch/${branchId}/bindMembers?memberId=${memberId}`
    );
  },
  unbindMemberToBranch(memberId, branchId) {
    return axiosNewInstance.post(
      `/branch/${branchId}/unbindMembers?memberId=${memberId}`
    );
  },
  removeProject(memberId, projectId) {
    return axiosInstance
      .post(
        `member/project/unbind?memberId=${memberId}&projectId=${projectId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  removeMemberFromProject(memberId, projectId) {
    return axiosNewInstance
      .post(`/project/${projectId}/unbindMembers?memberId=${memberId}`)
      .then((response) => {
        return response;
      });
  },
  removeAllMembersFromProject(projectId) {
    return axiosNewInstance.post(`/project/${projectId}/unbindAllMembers`);
  },
  addBranch(memberId, branchId) {
    return axiosInstance
      .post(
        `member/branch/bind?memberId=${memberId}&branchId=${branchId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  addBranchMemberNew(memberId, branchId) {
    return axiosNewInstance
      .post(`branch/${branchId}/bindMembers?memberId=${memberId}`)
      .then((response) => {
        return response;
      });
  },
  removeBranch(memberId, branchId) {
    return axiosInstance
      .post(
        `member/branch/unbind?memberId=${memberId}&branchId=${branchId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  removeBranchMemberNew(memberId, branchId) {
    return axiosNewInstance
      .post(`branch/${branchId}/unbindMembers?memberId=${memberId}`)
      .then((response) => {
        return response;
      });
  },
  removeAllMembersFromBranch(branchId) {
    return axiosNewInstance.post(`branch/${branchId}/unbindAllMembers`);
  },
  getMemberSomeFields(id, fields) {
    return axiosInstance
      .get(
        `member/get?fields=${fields}&memberIds=${id}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  getMemberProfileSomeFieldsNew(id) {
    return axiosNewInstance
      .get(
        `member/${id}/show?fields=Id,FirstName,LastName,Email,BirthDate,PhoneNumber,PositionId,IsPartTime,SinceDate,NdaSigned,Avatar,Projects,BranchIds,_featureList,NdaSignedAt `
      )
      .then((response) => {
        return response;
      });
  },
  memberUpdate(
    memberId,
    firstName,
    lastName,
    birthDate,
    sinceDate,
    phoneNumber,
    positionId,
    isPartTime
  ) {
    return axiosInstance
      .post(
        `member/update?memberId=${memberId}&firstName=${firstName}&lastName=${lastName}&birthDate=${birthDate}&sinceDate=${sinceDate}&phoneNumber=${phoneNumber}&positionId=${positionId}&isPartTime=${isPartTime}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  memberUpdateNew(
    memberId,
    birthDate,
    sinceDate,
    phoneNumber,
    positionId,
    isPartTime,
    isNdaSigned,
    ndaSignedAt
  ) {
    return axiosNewInstance.put(
      `member/${memberId}/update?fields=BirthDate,SinceDate,PhoneNumber,PositionId,IsPartTime,NdaSigned,NdaSignedAt`,
      {
        birthDate,
        sinceDate,
        phoneNumber,
        positionId,
        isPartTime,
        ndaSigned: isNdaSigned,
        ndaSignedAt,
      }
    );
  },
  allTasksList(id, fromDate, tillDate) {
    return axiosInstance
      .get(
        `taskTimeEntry/list?memberId=${id}&fromDate=${fromDate}&tillDate=${tillDate}&fields=value, date, description, id, projectId, status, extendedDescription, movedFromTaskId, taskNumber${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  allTasksListNew(memberId, fromDate, tillDate) {
    return axiosNewInstance.get(
      `taskTimeEntry?fields=*&memberId=${memberId}&fromDate=${fromDate}&tillDate=${tillDate}`
    );
  },
  openTasksDay(id, date) {
    return axiosInstance
      .get(
        `taskTimeEntry/openTasks?memberId=${id}&date=${date}&fields=value, date, description, id, projectId, status, extendedDescription${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  // hint(id, fromDate, tillDate) {
  //   return axiosInstance
  //     .get(
  //       `taskTimeEntry/hint?memberId=${id}&fromDate=${fromDate}&tillDate=${tillDate}${
  //         mode === 'development' ? '&sessionId=' + sessionId : ''
  //       }`
  //     )
  //     .then((response) => {
  //       return response;
  //     })
  //     .catch((error) => {
  //       return (
  //         (error.response && error.response.data) || 'Something went wrong'
  //       );
  //     });
  // },
  hintNew(id, fromDate, tillDate) {
    return axiosNewInstance.get(
      `taskTimeEntry/hint?memberId=${id}&fields=*&fromDate=${fromDate}&tillDate=${tillDate}`
    );
  },
  createTask(
    memberId,
    projectId,
    value,
    date,
    description,
    extendedDescription,
    status,
    movedFromTaskId
  ) {
    return axiosInstance
      .get(
        `taskTimeEntry/create?memberId=${memberId}&projectId=${projectId}&value=${value}&date=${date}&description=${description}&extendedDescription=${extendedDescription}&status=${status}&movedFromTaskId=${movedFromTaskId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  createTaskNew(day, description, memberId, minutes, projectId) {
    return axiosNewInstance.post(
      `taskTimeEntry/store?fields=Day,Description,MemberId,Minutes,ProjectId`,
      {
        day,
        description,
        memberId,
        minutes,
        projectId,
      }
    );
  },
  updateTask(
    tteId,
    projectId,
    value,
    date,
    description,
    extendedDescription,
    status
  ) {
    return axiosInstance
      .get(
        `taskTimeEntry/update?tteId=${tteId}&projectId=${projectId}&value=${value}&date=${date}&description=${description}&extendedDescription=${extendedDescription}&status=${status}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  updateTaskNew(tteId, day, description, memberId, minutes, projectId) {
    return axiosNewInstance.put(
      `taskTimeEntry/${tteId}/update?fields=Day,Description,MemberId,Minutes,ProjectId`,
      {
        day,
        description,
        memberId,
        minutes,
        projectId,
      }
    );
  },
  deleteTask(tteId) {
    return axiosInstance
      .get(
        `taskTimeEntry/delete?tteId=${tteId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  deleteTaskNew(tteId) {
    return axiosNewInstance.post(`taskTimeEntry/${tteId}/delete`);
  },
};
