import { memberReportApi } from '../../api/memberReportApi';
import { usersApi } from '../../api/usersApi';
import { projectsApi } from '../../api/projectsApi';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  error: false,
  branchesList: null,
  branchesListNew: null,
  period: {
    from: null,
    till: null,
  },
  reports: null,
  reportTotal: '',
  timeReports: null,
  projectList: null,
  projectListNew: null,
  projectsListBySearch: null,
  openedProject: null,
  openedProjectNew: null,
  openedBranch: null,
  addRemoveMemberError: null,
  addRemoveMemberSuccess: null,
  exportSuccess: null,
  createProjectError: null,
  createProjectSuccess: null,
};

const slice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setBranchesList(state, action) {
      state.branchesList = action.payload;
    },
    setBranchesListNew(state, action) {
      state.branchesListNew = action.payload;
    },
    setPeriod(state, action) {
      state.period.from = action.payload.periodFrom;
      state.period.till = action.payload.periodTill;
    },
    setReport(state, action) {
      state.reports = action.payload;
    },
    setReportTotal(state, action) {
      state.reportTotal = action.payload;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
    setTimeReports(state, action) {
      state.timeReports = action.payload;
    },
    updateTimeReports(state, action) {
      const memberId = action.payload.memberId;
      const overtime = action.payload.overtime;
      const confirmedById = action.payload.confirmedById;
      const confirmedDate = action.payload.confirmedDate;
      const updateReports = state.timeReports.map((item) => {
        if (item.memberId === memberId)
          return {
            ...item,
            overtimeHoursConfirmed: overtime,
            confirmedById,
            confirmedDate,
          };
        return item;
      });

      state.timeReports = updateReports;
    },
    setProjectsList(state, action) {
      state.projectList = action.payload;
    },
    setProjectsListNew(state, action) {
      state.projectListNew = action.payload;
    },
    setProjectsListBySearch(state, action) {
      state.projectsListBySearch = action.payload;
    },
    addInProjectsList(state, action) {
      state.projectList = [...state.projectList, action.payload];
      if (state.openedBranch)
        state.openedBranch = {
          ...state.openedBranch,
          projectIds: [...state.openedBranch.projectIds, action.payload.id],
        };
    },
    addInProjectsListNew(state, action) {
      state.projectsListBySearch = [
        ...state.projectsListBySearch,
        action.payload,
      ];
      if (state.openedBranch)
        state.openedBranch = {
          ...state.openedBranch,
          projects: [...state.openedBranch.projects, action.payload.id],
        };
    },
    deleteProject(state, action) {
      const id = action.payload;
      state.projectList = state.projectList.filter((item) => item.id !== id);
      state.openedProject = 'toList';
    },
    deleteProjectNew(state, action) {
      const id = action.payload;
      state.projectsList = state.projectList.filter((item) => item.id !== id);
      state.openedProjectNew = 'toList';
    },
    returnToList(state, action) {
      state.openedProject = 'toList';
    },
    returnToListNew(state, action) {
      state.openedProjectNew = 'toList';
    },
    updateProject(state, action) {
      const newProject = action.payload;
      state.projectList = state.projectList.map((item) => {
        if (item.id === newProject.id)
          return {
            ...item,
            name: newProject.name,
            archived: newProject.archived,
          };
        else return item;
      });
      state.openedProject = {
        ...state.openedProject,
        name: newProject.name,
        _display: newProject.name,
        archived: newProject.archived,
      };
    },
    updateProjectNew(state, action) {
      const newProject = action.payload;
      state.openedProject = 'toList';

      state.openedProjectNew = {
        ...state.openedProjectNew,
        name: newProject.name,
        archived: newProject.archived,
      };
    },
    addInBranchList(state, action) {
      state.branchesList = [...state.branchesList, action.payload];
    },
    addInBranchListNew(state, action) {
      state.branchesListNew = [...state.branchesListNew, action.payload];
    },
    updateBranch(state, action) {
      const newBranch = action.payload;
      state.openedBranch = {
        ...state.openedBranch,
        name: newBranch.name,
        code: newBranch.code,
      };
      state.branchesList = state.branchesList.map((item) => {
        if (item.id === newBranch.id)
          return { ...item, name: newBranch.name, code: newBranch.code };
        else return item;
      });
    },
    updateBranchNew(state, action) {
      const newBranch = action.payload;
      state.openedBranch = {
        ...state.openedBranch,
        name: newBranch.name,
        code: newBranch.code,
      };
      state.branchesListNew = state.branchesListNew.map((item) => {
        if (item.id === newBranch.id)
          return { ...item, name: newBranch.name, code: newBranch.code };
        else return item;
      });
    },
    deleteBranch(state, action) {
      const id = action.payload;
      state.branchesList = state.branchesList.filter((item) => item.id !== id);
      state.openedBranch = 'toList';
    },
    deleteBranchNew(state, action) {
      const id = action.payload;
      state.branchesListNew = state.branchesListNew.filter(
        (item) => item.id !== id
      );
      state.openedBranch = 'toList';
    },
    returnToBranchList(state, action) {
      state.openedBranch = 'toList';
    },
    setOpenedProject(state, action) {
      state.openedProject = action.payload;
    },
    setOpenedProjectNew(state, action) {
      state.openedProjectNew = action.payload;
    },
    addMemberInProject(state, action) {
      const memberId = action.payload;
      state.openedProject = {
        ...state.openedProject,
        memberIds: [...state.openedProject.memberIds, memberId],
      };
    },
    addMemberInProjectNew(state, action) {
      const memberId = action.payload;
      state.openedProjectNew = {
        ...state.openedProjectNew,
        members: [...state.openedProjectNew.members, memberId],
      };
    },
    removeMemberFromProject(state, action) {
      const memberId = action.payload;
      const newMemberList = state.openedProject.memberIds.filter(
        (item) => item !== memberId
      );
      state.openedProject = {
        ...state.openedProject,
        memberIds: newMemberList,
      };
    },
    removeMemberFromProjectNew(state, action) {
      const memberId = action.payload;
      const newMemberList = state.openedProjectNew.members.filter(
        (item) => item !== memberId
      );
      state.openedProjectNew = {
        ...state.openedProjectNew,
        members: newMemberList,
      };
    },
    removeAllMembersFromProject(state) {
      state.openedProjectNew = {
        ...state.openedProjectNew,
        members: [],
      };
    },
    setAddRemoveMemberError(state, action) {
      state.addRemoveMemberError = action.payload;
    },
    setAddRemoveMemberSuccess(state, action) {
      state.addRemoveMemberSuccess = action.payload;
    },
    setOpenedBranch(state, action) {
      state.openedBranch = action.payload;
    },
    addMemberInBranch(state, action) {
      const memberId = action.payload;
      state.openedBranch = {
        ...state.openedBranch,
        memberIds: [...state.openedBranch.memberIds, memberId],
      };
    },
    addMemberInBranchNew(state, action) {
      const memberId = action.payload;
      state.openedBranch = {
        ...state.openedBranch,
        members: [...state.openedBranch.members, memberId],
      };
    },
    removeMemberFromBranch(state, action) {
      const memberId = action.payload;
      const newMemberList = state.openedBranch.memberIds.filter(
        (item) => item !== memberId
      );
      state.openedBranch = { ...state.openedBranch, memberIds: newMemberList };
    },
    removeMemberFromBranchNew(state, action) {
      const memberId = action.payload;
      const newMemberList = state.openedBranch.members.filter(
        (item) => item !== memberId
      );
      state.openedBranch = { ...state.openedBranch, members: newMemberList };
    },
    removeAllMembersFromBranch(state) {
      state.openedBranch = { ...state.openedBranch, members: [] };
    },
    setExport(state, action) {
      state.exportSuccess = action.payload;
    },
    setCreateProjectError(state, action) {
      state.createProjectError = action.payload;
    },
    setCreateProjectSuccess(state, action) {
      state.createProjectSuccess = action.payload;
    },
    setCreateBranchError(state, action) {
      state.createBranchError = action.payload;
    },
    setCreateBranchSuccess(state, action) {
      state.createBranchSuccess = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setReport,
  setReportTotal,
  setOpenedProject,
  setOpenedProjectNew,
  setAddRemoveMemberError,
  setAddRemoveMemberSuccess,
  returnToList,
  returnToListNew,
  setOpenedBranch,
  returnToBranchList,
  setExport,
  setCreateBranchError,
  setCreateBranchSuccess,
  setCreateProjectError,
  setCreateProjectSuccess,
} = slice.actions;

// ---------------Projects---------------------------------

export function getBranches(memberId) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await projectsApi.getBranches(memberId);
      // console.log(response)
      let branches = response.data.object.items;
      dispatch(slice.actions.setBranchesList(branches));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getBranchesNew() {
  return async (dispatch) => {
    try {
      const response = await projectsApi.getBranchesNew();
      let branches = response.data.body.items;
      dispatch(slice.actions.setBranchesListNew(branches));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getBranch(branchIds) {
  return async (dispatch) => {
    try {
      const response = await projectsApi.getBranch(branchIds);
      let branch = response.data.object.items[0];
      dispatch(slice.actions.setOpenedBranch(branch));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getBranchNew(branchId) {
  return async (dispatch) => {
    try {
      const response = await projectsApi.getBranchNew(branchId);
      let branch = response.data.body;
      dispatch(slice.actions.setOpenedBranch(branch));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCurrentPeriod() {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await projectsApi.getCurrentPeriod();
      let period = response.data.object.item;
      dispatch(slice.actions.setPeriod(period));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getReports(
  memberIds,
  branchIds,
  projectIds,
  periodFrom,
  periodTill
) {
  return async (dispatch) => {
    try {
      const response = await projectsApi.getReports(
        memberIds,
        branchIds,
        projectIds,
        periodFrom,
        periodTill
      );

      let reports = response.data.object.items;
      dispatch(slice.actions.setReport(reports));
      if (response.data?.object?.totals?.sum)
        dispatch(slice.actions.setReportTotal(response.data.object.totals.sum));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getReportsExport(
  memberIds,
  branchIds,
  projectIds,
  periodFrom,
  periodTill
) {
  return async (dispatch) => {
    // dispatch(slice.actions.setExport(null));
    try {
      const response = await projectsApi.getReportsExport(
        memberIds,
        branchIds,
        projectIds,
        periodFrom,
        periodTill
      );

      if (response.status === 200 && !response.data.errors) {
        const url = `https://uc.qix.sx/api/v1/taskTimeEntry/report?memberIds=${memberIds}&branchIds=${branchIds}&projectIds=${projectIds}&periodFrom=${periodFrom}&periodTill=${periodTill}&exportFormat=xlsx&fields=extendedDescription,status,value,projectId,memberId,typeOfWork`;

        let link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.setExport(false));
    }
  };
}
export function getProjectList() {
  return async (dispatch) => {
    try {
      const response = await usersApi.getProjectsList();
      let projects = response.data.object.items;
      dispatch(slice.actions.setProjectsList(projects));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProjectListNew() {
  return async (dispatch) => {
    try {
      const response = await usersApi.getProjectsListNew();
      let projects = response.data.body.items;
      dispatch(slice.actions.setProjectsListNew(projects));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProjectsBySearch(query) {
  return async (dispatch) => {
    try {
      const response = await usersApi.getProjectsListBySearch(query);
      let projects = response.data.body.items;
      dispatch(slice.actions.setProjectsListBySearch(projects));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProject(id) {
  return async (dispatch) => {
    try {
      const response = await usersApi.getProject(id);
      let project = response.data.object.items[0];
      dispatch(slice.actions.setOpenedProject(project));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProjectNew(id) {
  return async (dispatch) => {
    try {
      const response = await usersApi.getProjectNew(id);
      let project = response.data.body;
      dispatch(slice.actions.setOpenedProjectNew(project));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function projectCreate(branchId, name, branchName) {
  return async (dispatch) => {
    dispatch(slice.actions.setCreateProjectError(null));
    dispatch(slice.actions.setCreateProjectSuccess(null));
    try {
      const response = await projectsApi.projectCreate(branchId, name);
      if (response.data.status === 200 && !response.data.errors?.length) {
        const id = response.data.object.item;
        dispatch(
          slice.actions.addInProjectsList({
            id,
            name: branchName + ' ' + name,
            memberIds: [],
          })
        );
      } else throw new Error(response.data.code.name);
      dispatch(slice.actions.setCreateProjectSuccess(true));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.setCreateProjectError(error.message));
    }
  };
}

export function projectCreateNew(branchId, name) {
  return async (dispatch) => {
    dispatch(slice.actions.setCreateProjectError(null));
    dispatch(slice.actions.setCreateProjectSuccess(null));
    try {
      const response = await projectsApi.projectCreateNew(branchId, name);
      if (response.data.status === 201 && response.data.errors === null) {
        const id = response.data.body.id;
        dispatch(slice.actions.addInProjectsListNew({ id, name, members: [] }));
      } else throw new Error(response.data.errors);
      dispatch(slice.actions.setCreateProjectSuccess(true));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.setCreateProjectError(error.message));
    }
  };
}

export function projectDelete(projectId) {
  return async (dispatch) => {
    try {
      const response = await projectsApi.projectDelete(projectId);
      if (response.data.status === 200 && !response.data.errors?.length) {
        dispatch(slice.actions.deleteProject(projectId));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function projectDeleteNew(projectId) {
  return async (dispatch) => {
    try {
      const response = await projectsApi.projectDeleteNew(projectId);
      if (response.data.status === 200 && response.data.errors === null) {
        dispatch(slice.actions.deleteProjectNew(projectId));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function projectUpdate(branchId, name, projectId, branchName, archived) {
  return async (dispatch) => {
    try {
      const response = await projectsApi.projectUpdate(
        branchId,
        name,
        projectId,
        archived
      );
      if (response.data.status === 200 && !response.data.errors?.length) {
        const id = response.data.object.item;
        dispatch(
          slice.actions.updateProject({
            id,
            name: branchName + ' ' + name,
            archived,
          })
        );
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function projectUpdateNew(branchId, name, projectId, archived) {
  return async (dispatch) => {
    try {
      const response = await projectsApi.projectUpdateNew(
        branchId,
        name,
        projectId,
        archived
      );
      if (response.data.status === 200 && response.data.errors === null) {
        const id = response.data.body.id;
        dispatch(slice.actions.updateProjectNew({ id, name, archived }));
      } else throw new Error(response.data.status_code);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addMemberInProject(memberId, projectId) {
  return async (dispatch) => {
    dispatch(slice.actions.setAddRemoveMemberError(null));
    dispatch(slice.actions.setAddRemoveMemberSuccess(null));

    try {
      const response = await usersApi.addProject(memberId, projectId);
      if (response.data.status === 200 && response.data.code.name === 'NONE') {
        dispatch(slice.actions.addMemberInProject(memberId));
        dispatch(slice.actions.setAddRemoveMemberSuccess(true));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.setAddRemoveMemberError(error.message));
    }
  };
}

export function addMemberInProjectNew(memberId, projectId) {
  return async (dispatch) => {
    dispatch(slice.actions.setAddRemoveMemberError(null));
    dispatch(slice.actions.setAddRemoveMemberSuccess(null));

    try {
      const response = await usersApi.addMemberToProject(memberId, projectId);
      if (
        response.data.status === 200 &&
        response.data.status_code === 'SUCCESS'
      ) {
        dispatch(slice.actions.addMemberInProjectNew(memberId));
        dispatch(slice.actions.setAddRemoveMemberSuccess(true));
      } else throw new Error(response.data.status_code);
    } catch (error) {
      dispatch(slice.actions.setAddRemoveMemberError(error.message));
    }
  };
}

export function removeMemberFromProject(memberId, projectId) {
  return async (dispatch) => {
    dispatch(slice.actions.setAddRemoveMemberError(null));
    dispatch(slice.actions.setAddRemoveMemberSuccess(null));

    try {
      const response = await usersApi.removeProject(memberId, projectId);
      if (response.data.status === 200 && response.data.code.name === 'NONE') {
        dispatch(slice.actions.removeMemberFromProject(memberId));
        dispatch(slice.actions.setAddRemoveMemberSuccess(true));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.setAddRemoveMemberError(error.message));
    }
  };
}

export function removeMemberFromProjectNew(memberId, projectId) {
  return async (dispatch) => {
    dispatch(slice.actions.setAddRemoveMemberError(null));
    dispatch(slice.actions.setAddRemoveMemberSuccess(null));

    try {
      const response = await usersApi.removeMemberFromProject(
        memberId,
        projectId
      );
      if (
        response.data.status === 200 &&
        response.data.status_code === 'SUCCESS'
      ) {
        dispatch(slice.actions.removeMemberFromProjectNew(memberId));
        dispatch(slice.actions.setAddRemoveMemberSuccess(true));
      } else throw new Error(response.data.status_code);
    } catch (error) {
      dispatch(slice.actions.setAddRemoveMemberError(error.message));
    }
  };
}

export function removeAllMembersFromProject(projectId) {
  return async (dispatch) => {
    try {
      await usersApi.removeAllMembersFromProject(projectId);
      dispatch(slice.actions.removeAllMembersFromProject());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ---------------Time Reports---------------------------------

export function getMemberReportList(periodFrom, periodTill) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await memberReportApi.memberReportList(
        periodFrom,
        periodTill
      );
      let reports = response.data.object.items;
      dispatch(slice.actions.setTimeReports(reports));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function overtimeConfirm(
  memberId,
  year,
  month,
  overtime,
  confirmedById,
  confirmedDate
) {
  return async (dispatch) => {
    try {
      const response = await memberReportApi.overtimeConfirm(
        memberId,
        year,
        month,
        overtime
      );
      if (response.data.status === 200 && !response.data.errors?.length) {
        dispatch(
          slice.actions.updateTimeReports({
            memberId,
            overtime,
            confirmedById,
            confirmedDate,
          })
        );
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function overtimeReject(memberId, year, month) {
  return async (dispatch) => {
    try {
      const response = await memberReportApi.overtimeReject(
        memberId,
        year,
        month
      );
      if (response.data.status === 200 && !response.data.errors?.length) {
        dispatch(
          slice.actions.updateTimeReports({
            memberId,
            overtime: null,
            confirmedById: null,
            confirmedDate: null,
          })
        );
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ---------------Branch Managment---------------------------------
export function branchCreate(code, name) {
  return async (dispatch) => {
    try {
      const response = await projectsApi.branchCreate(code, name);
      if (response.data.status === 200 && !response.data.errors?.length) {
        const id = response.data.object.item;
        dispatch(slice.actions.addInBranchList({ id, name, code }));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function branchCreateNew(code, name, tenantId) {
  return async (dispatch) => {
    dispatch(slice.actions.setCreateBranchError(null));
    dispatch(slice.actions.setCreateBranchSuccess(null));
    try {
      const response = await projectsApi.branchCreateNew(code, name, tenantId);
      const id = response.data.body.id;
      dispatch(slice.actions.addInBranchListNew({ id, name, code, tenantId }));
      dispatch(slice.actions.setCreateBranchSuccess(true));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.setCreateBranchError(error.message));
    }
  };
}

export function branchDelete(branchId) {
  return async (dispatch) => {
    try {
      const response = await projectsApi.branchDelete(branchId);
      if (response.data.status === 200 && !response.data.errors?.length) {
        dispatch(slice.actions.deleteBranch(branchId));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function branchDeleteNew(branchId) {
  return async (dispatch) => {
    try {
      const response = await projectsApi.branchDeleteNew(branchId);
      if (response.data.status === 200 && response.data.errors === null) {
        dispatch(slice.actions.deleteBranchNew(branchId));
      } else throw new Error(response.data.stat);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function branchUpdate(branchId, name, code) {
  return async (dispatch) => {
    try {
      const response = await projectsApi.branchUpdate(branchId, name, code);
      if (response.data.status === 200 && !response.data.errors?.length) {
        const id = response.data.object.item;
        dispatch(slice.actions.updateBranch({ id, name, code }));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function branchUpdateNew(branchId, name, code) {
  return async (dispatch) => {
    try {
      const response = await projectsApi.branchUpdateNew(branchId, name, code);
      if (response.data.status === 200 && response.data.errors === null) {
        const id = response.data.body.id;
        dispatch(slice.actions.updateBranchNew({ id, name, code }));
      } else throw new Error(response.data.status_code);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addMemberInBranch(memberId, branchId) {
  return async (dispatch) => {
    dispatch(slice.actions.setAddRemoveMemberError(null));
    dispatch(slice.actions.setAddRemoveMemberSuccess(null));

    try {
      const response = await usersApi.addBranch(memberId, branchId);
      if (response.data.status === 200 && response.data.code.name === 'NONE') {
        // const id = response.data.object.item;
        dispatch(slice.actions.addMemberInBranch(memberId));
        dispatch(slice.actions.setAddRemoveMemberSuccess(true));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.setAddRemoveMemberError(error.message));
    }
  };
}

export function addMemberInBranchNew(memberId, branchId) {
  return async (dispatch) => {
    dispatch(slice.actions.setAddRemoveMemberError(null));
    dispatch(slice.actions.setAddRemoveMemberSuccess(null));

    try {
      const response = await usersApi.addBranchMemberNew(memberId, branchId);

      if (
        response.data.status === 200 &&
        response.data.status_code === 'SUCCESS'
      ) {
        dispatch(slice.actions.addMemberInBranchNew(memberId));
        dispatch(slice.actions.setAddRemoveMemberSuccess(true));
      } else throw new Error(response.data.status_code);
    } catch (error) {
      dispatch(slice.actions.setAddRemoveMemberError(error.message));
    }
  };
}

export function removeMemberFromBranch(memberId, branchId) {
  return async (dispatch) => {
    dispatch(slice.actions.setAddRemoveMemberError(null));
    dispatch(slice.actions.setAddRemoveMemberSuccess(null));

    try {
      const response = await usersApi.removeBranch(memberId, branchId);
      if (response.data.status === 200 && response.data.code.name === 'NONE') {
        dispatch(slice.actions.removeMemberFromBranch(memberId));
        dispatch(slice.actions.setAddRemoveMemberSuccess(true));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.setAddRemoveMemberError(error.message));
    }
  };
}

export function removeMemberFromBranchNew(memberId, branchId) {
  return async (dispatch) => {
    dispatch(slice.actions.setAddRemoveMemberError(null));
    dispatch(slice.actions.setAddRemoveMemberSuccess(null));

    try {
      const response = await usersApi.removeBranchMemberNew(memberId, branchId);
      if (
        response.data.status === 200 &&
        response.data.status_code === 'SUCCESS'
      ) {
        dispatch(slice.actions.removeMemberFromBranchNew(memberId));
        dispatch(slice.actions.setAddRemoveMemberSuccess(true));
      } else throw new Error(response.data.status_code);
    } catch (error) {
      dispatch(slice.actions.setAddRemoveMemberError(error.message));
    }
  };
}

export function removeAllMembersFromBranch(branchId) {
  return async (dispatch) => {
    dispatch(slice.actions.setAddRemoveMemberError(null));
    dispatch(slice.actions.setAddRemoveMemberSuccess(null));
    try {
      const response = await usersApi.removeAllMembersFromBranch(branchId);
      if (
        response.data.status === 200 &&
        response.data.status_code === 'SUCCESS'
      ) {
        dispatch(slice.actions.removeAllMembersFromBranch());
        dispatch(slice.actions.setAddRemoveMemberSuccess(true));
      } else throw new Error(response.data.status_code);
    } catch (error) {
      dispatch(slice.actions.setAddRemoveMemberError(error.message));
    }
  };
}

// export function addBranch(memberId, branchId) {
//   return async dispatch => {

//     dispatch(slice.actions.setAddRemoveProjectError(null));
//     dispatch(slice.actions.setAddRemoveProjectSuccess(null));

//     try {
//       const response = await usersApi.addBranch(memberId, branchId);
//       if (response.data.status === 200 && response.data.code.name === "NONE") {
//         // const id = response.data.object.item;
//         dispatch(slice.actions.addBranchInList(branchId));
//         dispatch(slice.actions.setAddRemoveProjectSuccess(true));
//       } else throw new Error(response.data.code.name);
//     } catch (error) {
//       dispatch(slice.actions.setAddRemoveProjectError(error.message));
//     }
//   };
// }

// export function removeBranch(memberId, branchId) {
//   return async dispatch => {

//     dispatch(slice.actions.setAddRemoveProjectError(null));
//     dispatch(slice.actions.setAddRemoveProjectSuccess(null));

//     try {
//       const response = await usersApi.removeBranch(memberId, branchId);
//       if (response.data.status === 200 && response.data.code.name === "NONE") {
//         dispatch(slice.actions.removeBranchFromList(branchId));
//         dispatch(slice.actions.setAddRemoveProjectSuccess(true));
//       } else throw new Error(response.data.code.name);

//     } catch (error) {
//       dispatch(slice.actions.setAddRemoveProjectError(error.message));
//     }
//   };
// }
